<template>
  <v-app>
    <asr/>
  </v-app>
</template>

<script>

import asr from "./components/asr";
export default {
  name: 'App',

  components: {
    asr
  },

  data: () => ({
    //
  }),
};
</script>

<style>
   .v-application--wrap {
     min-height: 0 !important;
   }
</style>
