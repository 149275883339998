<template>
  <div class="mt-10 mb-10">
    <v-textarea  dir="rtl" outlined v-model="text" readonly/>
    <div class="actions">
      <v-btn :loading="loadingVoice" v-if="isRecording" :disabled="loadingFile"  @click="stopAudio" depressed  color="primary" class="mr-2"><v-icon>mdi-stop-circle-outline</v-icon> Stop</v-btn>
      <v-btn :loading="loadingVoice" v-else :disabled="loadingFile"  @click="initRecording" depressed  color="primary" class="mr-2"><v-icon>mdi-microphone-outline</v-icon> Speak</v-btn>
      <v-btn :loading="loadingFile" :disabled="loadingVoice || isRecording" depressed outlined color="primary" @click="$refs.file.click()" >Upload File</v-btn>
      <input type="file" accept="audio/wav" class="botter-file-uploader" @change="uploadFile" ref="file" >
      <!--for record-->
    </div>
    <div>

    </div>
  </div>
</template>

<script>
  import Recorder from 'recorder-js';

  export default {
    name: "asr",
    data(){
      return {
        text: '',
        loadingFile: false,
        loadingVoice: false,
        mediaRecorder: {},
        recorder: {},
        isRecording: false,
        blob: {},
        gumStream: {}
      }
    },
    mounted() {
      //this.initRecording()
    },
    methods: {
      uploadFile(){
        this.loadingFile = true
        let formObject = new FormData()
        formObject.append("file", event.target.files[0])
        this.getText(formObject)
      },
      initRecording() {
        const audioContext =  new (window.AudioContext || window.webkitAudioContext)();

        this.recorder = new Recorder(audioContext, {
          // An array of 255 Numbers
          // You can use this to visualize the audio stream
          // If you use react, check out react-wave-stream

        });


        navigator.mediaDevices.getUserMedia({audio: true})
          .then(stream => {
            this.recorder.init(stream)
            this.recorder.start()
            this.isRecording = true
          })
          .catch(err => console.log('Uh oh... unable to get stream...', err));
      },
      stopAudio() {
        this.recorder.stop()
          .then(({blob, buffer}) => {
            this.isRecording = false
            this.loadingVoice = true
            this.blob = blob;
            let fd = new FormData();
            fd.append("file", blob, new Date().toISOString()+'.wav');
            this.getText(fd)
            // buffer is an AudioBuffer
          });//stop microphone access
      },
      getText(formObject) {
        this.$http.post('/upload',formObject)
        .then(res=>{
          this.text= res.data.result
          this.loadingFile = false
          this.loadingVoice = false
        })
        .catch(err=>{
          this.loadingFile = false
          this.loadingVoice = false
        })
      },
      blobToFile(theBlob, fileName){
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
      }
    }
  }
</script>

<style scoped>
.botter-file-uploader {
  width: 0;
  height: 0;
}
</style>
